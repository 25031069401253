.logo {
  height: 60px;
}

#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  /* background: rgba(255, 255, 255, 0.3); */
}

.ant-layout-content {
  width: calc(100% - 32px);
  overflow: auto;
}

.daily-podcasts .today {
  color: rgb(129, 216, 207);
}

.audio-content {
  height: 500px !important;
}
.audio-cn-content-textarea {
  width: 500px;
  height: 500px !important;
}

.article-content {
  width: 500px;
  height: 500px;
}

.podcast-cover {
  width: 100%;
}

.image-list {

}

.image-item {
  position: relative;
  margin-left: 10px;
  display: inline-block;
}

.image-tip {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  background: #0abab5;
  color: white;
  opacity: 0.9;
}

.generated-img {
  width: 512px;
  height: 512px;
}
